<sym-card-dialog *ngIf="data.addMeter" [isValid]="decomissionGroup.valid && meterDetails.valid && !meterNoExists" [title]="title + 'Meter: ' + data.meter.meterNo" [buttonProperties]="decomMeterButtomProp" [closeMethod]="closeMethod">
  <ng-template [ngTemplateOutlet]="decommissionForm"></ng-template> 
</sym-card-dialog>

<sym-card-dialog *ngIf="!data.addMeter" [isValid]="decomissionGroup.valid" [title]="title + 'Meter: '  + data.meter.meterNo" [buttonProperties]="decomButtomProp" [closeMethod]="closeMethod">
  <ng-template [ngTemplateOutlet]="decommissionForm"></ng-template> 
</sym-card-dialog>

  <ng-template #decommissionForm>
    <mat-dialog-content>
      <div [ngStyle]="{'height': data.addMeter ? '48VH' : '100%'}">
        <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
          <form [formGroup]="decomissionGroup">
            <div fxLayout="column">
              <div style="display: flex;flex-direction: row;gap: 12px">
            <div >
           
              <p>
                Please note that decommissioning a meter is the final process in discontinuing and ending the shelf life of the actual meter and can not be undone! 
            </p>
              
            </div>
           
                </div>
              <div style="display: flex;flex-direction: row;gap: 12px">
                <mat-form-field appearance="outline">
                  <mat-label>Decommission Reading</mat-label>
                  <input matInput type="number" formControlName="decommissionReading" required>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="utilityTypeNumber > 1">
                  <mat-label>Decommission Reading 2</mat-label>
                  <input matInput type="number" formControlName="decommissionReading2" required>
                </mat-form-field>
              </div>

              <div style="display: flex;flex-direction: row;gap: 12px">
                <mat-form-field appearance="outline" *ngIf="utilityTypeNumber > 2">
                  <mat-label>Decommission Reading 3</mat-label>
                  <input matInput type="number" formControlName="decommissionReading3" required>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="utilityTypeNumber > 3">
                  <mat-label>Decommission Reading 4</mat-label>
                  <input matInput type="number" formControlName="decommissionReading4" required>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Decommission Reason</mat-label>
                    <input matInput  formControlName="decommissionReason" required>
                </mat-form-field>
              </div>
              <mat-hint>*Please make sure that the correct meter was selected.</mat-hint>
              <!-- <button (click)="decomissionMeter()">Test</button> -->
            </div>
          </form>
          <div fxLayout="column" *ngIf="data.addMeter">
            <form [formGroup]="meterDetails">
              <h3>Replacement Meter</h3>
              <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;">
                <div fxLayout="column">
                  <div fxLayout="row wrap" fxLayoutGap="14px">
    
                    <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                      <mat-label>Utility Type</mat-label>
                      <mat-select style="color: black;"  formControlName="utilityType" (ngModelChange)="meterChange()" required>
                          <mat-option *ngFor="let meter of meterUtility" style="color: black;" [value]="meter">
                              {{meter.name}}</mat-option>
                      </mat-select>
                      </mat-form-field>
    
                      <div fxLayout="column" style="margin-top: 5px;" fxFlex="45">
                        <mat-form-field appearance="outline" >
                          <mat-label>Meter Number</mat-label>
                          <input matInput style="color: black;"  formControlName="meterNo" (ngModelChange)="checkIfNumberExists()" required>
                          <mat-hint *ngIf="checkExists">Checking if number exists</mat-hint>
                        </mat-form-field>
                        <mat-error *ngIf="!checkExists && meterNoExists" style="margin-top: -1em;padding-bottom: 1.5em; font-size: 75%;">Meter Number already exists</mat-error>
                      </div>
    
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                        <mat-label>Reading Method</mat-label>
                        <mat-select style="color: black;" (ngModelChange)="checkCaptureType()" formControlName="readingMethod" required>
                          <mat-option *ngFor="let readingMethod of readingMethods" style="color: black;" [value]="readingMethod">
                            {{readingMethod}}</mat-option>
                        </mat-select>
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                        <mat-label>Capture Type</mat-label>
                        <mat-select data-cy="captureType" style="color: black;" formControlName="captureType" [(value)] ="meter.captureType" required>
                          <mat-option *ngFor="let type of captureType" [value]="type.value" >
                            {{type.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && !showWater">
                        <mat-label>Tariff Type</mat-label>
                        <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff" required>
                          <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                            <mat-option *ngFor="let tariff of supplier.tariffList" [value]="[tariff.id]" >
                              {{tariff.name}}</mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
        
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && showWater">
                        <mat-label>Tariff Type</mat-label>
                        <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff"  multiple required>
                          <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                            <mat-option *ngFor="let tariff of supplier.tariffList" [value]="tariff.id" >
                              {{tariff.name}}</mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="!showWater">
                        <mat-label>Amps</mat-label>
                        <input matInput style="color: black;" formControlName="amps" type="number" required>
                        <mat-error *ngIf="!showWater && meterDetails.value.amps <= 0">Amps cannot be smaller than 1</mat-error>
                      </mat-form-field>
              
                      <mat-form-field appearance="outline" style="margin-top: 5px; color: black;" fxFlex="45" >
                          <mat-label>Commission date</mat-label>
                          <input matInput [matDatepicker]="picker"  formControlName="commissionDate" required>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
    
                      <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                        <mat-label>Commission Reading 1</mat-label>
                        <input matInput data-cy="commissionReading" style="color: black;" formControlName="commissionReading" type="number">
                      </mat-form-field>
              
                      <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission2"
                        fxFlex="45">
                        <mat-label>Commission Reading 2</mat-label>
                        <input matInput data-cy="commissionReading2" style="color: black;" formControlName="commissionReading2" type="number">
                      </mat-form-field>
              
                      <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission3"
                        fxFlex="45">
                        <mat-label>Commission Reading 3</mat-label>
                        <input matInput data-cy="commissionReading3" style="color: black;" formControlName="commissionReading3" type="number">
                      </mat-form-field>
              
                      <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission4"
                        fxFlex="45">
                        <mat-label>Commission Reading 4</mat-label>
                        <input matInput data-cy="commissionReading4" style="color: black;" formControlName="commissionReading4" type="number">
                      </mat-form-field>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>