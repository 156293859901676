<!-- Nothing will display if there is a TOU manual meter -->
<sym-content-card [title]="'Meter Readings'" [titleButton]="titleButton"
  *ngIf="((utilityType && utilityType.type != 'ELEC_3P_TOU') || (utilityType && utilityType.type === 'ELEC_3P_TOU' && (meterDetails && meterDetails.captureType && meterDetails.captureType.includes('MANUAL')))) && (readingHistory && readingHistory.status === 'NOT_CAPTURED') && (superUser || (!superUser && !stopCapture))">
  <ng-template [ngTemplateOutlet]="readingsTable"></ng-template>
</sym-content-card>

<sym-content-card [title]="'Meter Readings'" *ngIf="(meterDetails && meterDetails.captureType && meterDetails.captureType.includes('ONLINE') && (utilityType && utilityType.type === 'ELEC_3P_TOU')) || 
  (readingHistory && readingHistory.status !== 'NOT_CAPTURED') || (!superUser && stopCapture)">
  <ng-template [ngTemplateOutlet]="readingsTable"></ng-template>
</sym-content-card>

<ng-template #readingsTable>
  <div class="table-container">
    <mat-table [dataSource]="meterReadingDS">
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef fxFlex="13" style="height: 80%;" class="table-header">Captured Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" fxFlex="13" style="color: black;">
          <div *ngIf="element.readingTS && i === 0">
            <span style="font-weight: bold;">{{element.readingTS | date:'yyyy-MM-dd'}}</span>
          </div>
          <div *ngIf="i != 0">
            <div *ngIf="element.name">
              {{utilityType && utilityType.type.includes('ELEC_3P_KVA') && element.name === 'Phase 1' ? 'Total' :
              element.name.includes('KVA') ? 'KVA' : element.name}}
            </div>
            <div *ngIf="!element.name">
              {{checkReadingText(i)}}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reading">
        <mat-header-cell *matHeaderCellDef fxFlex="12" style="height: 80%;" class="table-header">{{utilityType.type ===
          'ELEC_3P_TOU' ? 'Utilization' : 'Reading'}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="12" style="color: black;">
          <div *ngIf="element.value && i != 0">
            {{element.value != 'NaN' ? (element.value | number:'1.0-4') : ''}}
          </div>
          <div *ngIf="!element.value && i != 0">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="utilityType">
        <mat-header-cell *matHeaderCellDef fxFlex="12" style="height: 80%;" class="table-header">Utility Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="12" style="color: black;">
          <div *ngIf="i != 0">
            {{utilityType.type}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxFlex="12" style="height: 80%;" class="table-header">Status
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="12" style="color: black;">
          <div *ngIf="i != 0">
            {{readingHistory.status}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="captureType">
        <mat-header-cell *matHeaderCellDef fxFlex="9" style="height: 80%;" class="table-header">Capture Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="9" style="color: black;"
          matTooltip="{{meterDetails.captureType ? meterDetails.captureType : meterPeriodStatus.readingCaptureType}}"
          matTooltipPosition="above">
          <div *ngIf="i != 0 && meterDetails.captureType">
            {{meterDetails.captureType.length > 8 ? (meterDetails.captureType | slice: 0:8) + '...' :
            meterDetails.captureType}}
          </div>
          <div *ngIf="i != 0 && !meterDetails.captureType">
            {{meterPeriodStatus.readingCaptureType.length > 8 ? (meterPeriodStatus.readingCaptureType | slice: 0:8) +
            '...' : meterPeriodStatus.readingCaptureType}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="capturedBy">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Captured By
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="15" style="color: black;"
          matTooltip="{{element.capturedBy}}" matTooltipPosition="above">
          <div *ngIf="element.capturedBy && i != 0">
            {{element.capturedBy.length > 18 ? (element.capturedBy | slice: 0:18) + '...' : element.capturedBy}}
          </div>
          <div *ngIf="!element.capturedBy && i != 0">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="flag">
        <mat-header-cell *matHeaderCellDef fxFlex="5" style="height: 80%;" class="table-header">Warning
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="5" style="color: black;"
          matTooltip="{{setFlagWarningText()}}" matTooltipPosition="above">
          <div fxLayout="column" *ngIf="i != 0">
            <div *ngIf="readingHistory.flagReason !== undefined && readingHistory.flagReason !== 'NONE'">
              <div>
                <mat-icon style="color: red;"
                  [ngStyle]="{'font-size': readingHistory.flagReason.includes('NEGATIVE') || readingHistory.flagReason.includes('ZERO') ? 'large' : 'x-large'}">{{readingHistory.flagReason.includes('OVER')
                  ? 'warning' : readingHistory.flagReason.includes('UNDER') ? 'trending_down' :
                  readingHistory.flagReason.includes('NEGATIVE') ? 'exposure_neg_1' :
                  readingHistory.flagReason.includes('ZERO') ? 'exposure_zero' : 'notification_important'}}</mat-icon>
              </div>
            </div>
            <div *ngIf="readingHistory.flagReason === undefined || readingHistory.flagReason === 'NONE'">
              <div>
                -
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef fxFlex="17" style="height: 80%;"
          [ngStyle]="{'padding-left': windowWidth <= 1490 ? '2em' : ''}" class="table-header">Image
        </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="17" style="color: black;">
          <div *ngIf="i != 0">
            <div *ngIf="thumbnail.length > 0 && thumbnail[i-1] != ''" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon style="padding-right: 5px;">image</mat-icon><a (click)="viewImage(i)">View Image</a>
            </div>
            <div *ngIf="thumbnail.length === 0 || (thumbnail.length > 0 && thumbnail[i-1] === '')" fxLayout="row"
              fxLayoutAlign="center center">
              <mat-icon style="padding-right: 5px;">broken_image</mat-icon><span>No Image Available</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="5" style="color: grey;">Actions </mat-header-cell>
        <mat-cell *matCellDef="let element; index as i;" fxFlex="5">
          <button
            [disabled]="((readingHistory.approvalStatus === 'APPROVED' ||
                                readingHistory.approvalStatus === 'SUBMITTED' || (utilityType.type === 'ELEC_3P_TOU' && meterDetails.captureType === 'ONLINE')) && !superUser) ||
                                (readingHistory.approvalStatus === 'OVERWRITTEN') ||
                                (!superUser && stopCapture) || (superUser && utilityType.type === 'ELEC_3P_TOU' && meterDetails.captureType === 'ONLINE' && (readingHistory.approvalStatus !== 'SUBMITTED'))"
            matTooltipPosition="left" *ngIf="!readingHistory.status.includes('DECOMMISSIONED') && i === 0"
            matTooltip="View Menu" mat-icon-button class="mobile-hidden mat-light-green-700-bg"
            [matMenuTriggerFor]="readingMenu">
            <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
          </button>
        </mat-cell>

        <mat-menu #readingMenu="matMenu" xPosition="before" style="padding: 10px;">
          <div
            *ngIf="((!superUser && (readingHistory.approvalStatus === 'CAPTURED' || readingHistory.approvalStatus === 'REJECTED' || readingHistory.approvalStatus === 'MODIFIED')) || superUser) && (utilityType.type != 'ELEC_3P_TOU' || (utilityType.type === 'ELEC_3P_TOU' && meterDetails.captureType.includes('MANUAL')))">
            <button mat-menu-item
              (click)="openUpdateReadings()"><mat-icon>edit</mat-icon>{{readingHistory.approvalStatus === 'APPROVED' ?
              'Overwrite ' : 'Update '}} Period {{readingList.length > 2 ? 'Readings' : 'Reading'}}</button>
            <mat-divider *ngIf="!readingHistory.status.includes('DECOMMISSIONED')"></mat-divider>
          </div>
          <div
            *ngIf="(!superUser && (readingHistory.approvalStatus === 'CAPTURED' || readingHistory.approvalStatus === 'MODIFIED')) || (superUser)">
            <button mat-menu-item (click)="approveReading()"><mat-icon style="color: green;">check</mat-icon>{{superUser
              ? 'Approve ' : 'Submit '}} {{readingList.length > 2 ? 'Readings' : 'Reading'}}</button>
            <mat-divider></mat-divider>
          </div>
          <div>
            <button mat-menu-item (click)="rejectReading(false)"
              *ngIf="!readingHistory.status.includes('DECOMMISSIONED')  && superUser && readingHistory.approvalStatus != 'APPROVED' && (readingHistory.approvalStatus != 'REJECTED' && (utilityType.type != 'ELEC_3P_TOU' || (utilityType.type === 'ELEC_3P_TOU' && meterDetails.captureType.includes('MANUAL'))))"><mat-icon
                style="color: red">close</mat-icon>Reject {{readingList.length > 2 ? 'Readings' : 'Reading'}}</button>
          </div>
        </mat-menu>
      </ng-container>

      <mat-header-row *matHeaderRowDef="meterReadingColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: meterReadingColumns;"></mat-row>
    </mat-table>
  </div>
</ng-template>

<sym-content-card [title]="'History'"
  *ngIf="meterDetails && readingHistory.approvalStatus != 'APPROVED' && readingHistory.approvalStatus != 'OVERWRITTEN' && superUser && !outsideTDate">
  <div class="table-container">
    <mat-table [dataSource]="readingHistoryDS">
      <!-- <ng-container matColumnDef="selector">
      <mat-header-cell *matHeaderCellDef fxFlex="10" style="height: 80%;" class="table-header">
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index" fxFlex="10" style="color: black;">
        <mat-checkbox style="display: none;" (click)="$event.stopPropagation()" [ngModel]="selectedIndex === i"
                          (change)="changeSelection($event, i)"
                          [disabled]="true"
                          [checked]="selection.isSelected(element)">
            </mat-checkbox>
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef fxFlex="35" style="height: 80%;" class="table-header">Date
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="35" style="color: black;">
          <div *ngIf="element.readingTS">
            {{element.readingTS | date:'yyyy-MM-dd'}}
          </div>
          <div *ngIf="!element.readingTS">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reading">
        <mat-header-cell *matHeaderCellDef fxFlex="25" style="height: 80%;" class="table-header">{{utilityType &&
          utilityType.type.includes('ELEC_3P_3') ? 'Phase 1' : 'Reading 1'}}
          red
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;">
          <div
            *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[0].value">
            {{element.readingValueList[0].value != 'NaN' ? (element.readingValueList[0].value | number:'1.0-4') : ''}}
          </div>
          <div
            *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0) 
             || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[0].value)">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="standard">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Standard
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.readingValueList && element.readingValueList.length > 0">
            {{element.readingValueList[0].value}}
          </div>
          <div *ngIf="!element.readingValueList">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offPeak">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Off Peak
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[2].value">
            {{element.readingValueList[1].value != 'NaN' ? (element.readingValueList[1].value | number:'1.0-4') : ''}}
          </div>
          <div *ngIf="!element.readingValueList">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="peak">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Peak
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[0].value">
            {{element.readingValueList[2].value != 'NaN' ? (element.readingValueList[2].value | number:'1.0-4') : ''}}
          </div>
          <div *ngIf="!element.readingValueList">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reading2">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">{{utilityType &&
          utilityType.type.includes('ELEC_3P_3') ? 'Phase 2' : 'Reading 2'}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[1].value">
            {{element.readingValueList[1].value != 'NaN' ? (element.readingValueList[1].value | number:'1.0-4') : ''}}
          </div>
          <div *ngIf="!element.readingValueList">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reading3">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">{{utilityType &&
          utilityType.type.includes('ELEC_3P_3') ? 'Phase 3' : 'Reading 3'}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[2].value">
            {{element.readingValueList[2].value != 'NaN' ? (element.readingValueList[2].value | number:'1.0-4') : ''}}
          </div>
          <div *ngIf="!element.readingValueList">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="kva">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">KVA
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div>
            {{utilityType | kva: element.readingValueList | number:'1.0-4'}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="capturedBy">
        <mat-header-cell *matHeaderCellDef fxFlex="30" style="height: 80%;" class="table-header">Captured By
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30" style="color: black;">
          <div *ngIf="element.createdBy">
            {{element.createdBy}}
          </div>
          <div *ngIf="!element.createdBy">
            -
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="readingHistoryColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: readingHistoryColumns;"></mat-row>
    </mat-table>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 2em;"
    *ngIf="!readingHistory.status.includes('DECOMMISSIONED')">
    <button mat-button style="color: #41535D;border-radius: 100px;border: 1px solid #E7E7E7;"
      (click)="selectedIndex = undefined; selectedRow = '';">Clear Selection</button>
    <button (click)="replacePeriodReading()" mat-button
      style="color: white;background-color: #41535D;border-radius: 100px;margin-left: .5em;">Use As Final
      Reading<mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
</sym-content-card>